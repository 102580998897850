import React from 'react'
import Index from '../components/it'

 const IT = () => {
    return (
        <>
            <Index />
        </>
    )
}
export default IT;