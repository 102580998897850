import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageRight, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const Unique = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title className="animate-in-slide-up">A Long Term Partner.</Title>
            <Paragraph className="animate-in-slide-up">
              Being alone can be the scariest feeling in the world. This is especially true when dealing with the
              technology you use at home or at your business. Equipment will degrade and break. Software will become
              obsolete. Criminals will find new ways to skirt security. But staying on top of your technology needs
              doesn’t have to be scary. JL Tech will help you for the long haul. We will keep your systems
              functioning their best so you can concentrate on what is important to you.
            </Paragraph>
          </Col>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageRight className="animate-in-slide-left">
              <StaticImage
                src={"../../images/marketing_2.jpg"}
                layout="constrained"
                alt="unique design for your needs"
                loading="eager"
                placeholder="blurred"
              />
            </ImageRight>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Unique);

const Wrapper = styled(Section)`
  position: relative;
  background: rgba(255, 255, 255, .75);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Content = styled(Row)`
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 80px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 100px;
    margin-bottom: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 6vw;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .9);
  text-transform: capitalize;
  margin-bottom: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
`;
