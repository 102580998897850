import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";

const Help = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Title className="animate-in-slide-up">Building The Future.</Title>
          <Paragraph className="animate-in-slide-up">
            Today’s technology can be confusing. Knowing how to assemble the needed equipment, build the
            infrastructure needed, and setting up the software are all daunting tasks for the uninitiated.
            But there is no need to face it alone. JL Tech’s experienced technicians know how to assemble and
            install the future, for both commercial and residential applications.
          </Paragraph>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Help);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, .5);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 60%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 50%;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;
