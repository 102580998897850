import React from "react";
import Commercial from "./Commercial";
import Residential from "./Residential";
import Header from "./Header";
import Help from "./Help";
import Security from "./Security";
import Layout from '../Layout'
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import Solutions from "./Solutions";
import Unique from "./Unique";
import HorizontalScroll from "../HorizontalScroll";
import Contact from "../contact";

const Index = () => {

  const underlay = () => {
    return (
      <>
        <Background>
          <StaticImage
            src="../../images/it_hero.jpg"
            alt="Information Technology (IT) Services"
            loading="eager"
          />
        </Background>
        <Foreground />
      </>
    );
  }

  return (
    <Layout underlay={underlay()} metatags={{
      title: "JLTech Information Technology - Home & Office + Security",
      desc: `JLTech have extensive experience with hardware, software, and security systems.  Partner with 
        our JLTech to succeed in this often daunting world of information technology.`,
      keywords: `low voltage installers near me, low voltage installers spokane, low voltage installers coeur d'alene,
        security systems near me, security system installers spokane, security system installers coeur d'alene, 
        it services coeur d'alene, jltech, cda`
    }} snap>
      <Header id="header" />
      <Help id="help" />
      <Solutions id="solutions" />
      <HorizontalScroll id="it-services">
        <Commercial id="commercial" />
        <Residential id="residential" />
        <Security id="security" />
      </HorizontalScroll>
      <Unique id="unique" />
      <Chat />
    </Layout>
  );
};

export default Index;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;

const Background = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, #412ddb 0%, #3a28c500 100%) 0% 0% no-repeat padding-box;
`;