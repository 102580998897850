import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import styled from "styled-components";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS, PATHS} from "../../constants";
import {StaticImage} from "gatsby-plugin-image";
import ReadMore from "../ReadMore";
import {DevCustomized} from "../icons/DevCustomized";
import {DevSeo} from "../icons/DevSeo";
import {DevCleanCode} from "../icons/DevCleanCode";
import {DevResponsive} from "../icons/DevResponsive";
import Benefits from "./Benefits";

const Residential = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Title className="animate-in-slide-up">
          Residential IT.
        </Title>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Paragraph className="animate-in-slide-up">
              It wasn’t very long ago that having a television was the smartest part of your home. Now, with
              the explosion in popularity of Internet of Things (IoT), the “smart” home is becoming more prevalent.
              Having basic tasks, entertainment, information, and even security integrated into one seamless
              presence is appealing. But smart technology can be confusing to install. Letting JL Tech install
              your smart home services is, well, smart.
            </Paragraph>
            <ReadMore to={PATHS.development} className="animate-in-slide-up">Read more</ReadMore>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Benefits trigger={`#${props.id}`} items={[
              {label: 'Smart Homes', icon: <DevCustomized />},
              {label: 'Entertainment', icon: <DevSeo />},
              {label: 'Home offices', icon: <DevResponsive />},
              {label: 'Security', icon: <DevCleanCode />},
            ]} />
          </Col>
        </Content>
      </Container>
      <Background className="animate-in-background">
        <StaticImage
          src="../../images/develop_hero.jpg"
          alt="Unified end to end solutions."
          loading="eager"
          transformOptions={{grayscale: true}}
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};

export default forwardRef(Residential);

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(241, 240, 245, .5) 0%, rgba(241, 240, 245, 1) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    overflow: visible;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .9);
  text-transform: capitalize;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
`;


